import { FC } from "react";
import Marquee from "react-fast-marquee";

import { SubHeaderProps } from "./types";

import styles from "./index.module.css";

export const SubHeader: FC<SubHeaderProps> = ({ text }) => {
  return (
    <Marquee className={styles["wrapper"]} autoFill speed={85}>
      <span>{text}</span>
    </Marquee>
  );
};
